import PropTypes from "prop-types";
import React from "react";
export default class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null, info: "" };

  static propTypes = {
    errorMessage: PropTypes.string,
    fallback: PropTypes.node,
    children: PropTypes.node.isRequired
  };

  componentDidCatch(error, info) {
    console.error(error, info);
    window?.DD_RUM?.addError(error, { type: "Root level error" });
    this.setState({ hasError: true, error, info });
  }

  renderError = () => {
    let {
      state: { error },
      props: { errorMessage = error.message, fallback }
    } = this;
    if (fallback) {
      return fallback;
    }
    return process.env.BUILD_ENV === "production" ? (
      errorMessage
    ) : (
      <div className="wp-error">
        {errorMessage} <pre>{this.state.info.componentStack}</pre>
      </div>
    );
  };

  render() {
    return this.state.hasError ? this.renderError() : this.props.children;
  }
}
