import { isSuccess } from "common/api-status/utils";
import {
  actions as notificationActions,
  constants as notificationTypes
} from "common/inline-notifications/ducks";
import { UserDetailsSdk } from "common/sdk/user-details";
import commonServices from "common/services";
import { actionTypes as sessionActionTypes } from "common/session-management/ducks";
import { mapEffect } from "map-effect";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import services from "./services";

export const detailsSagas = [
  takeEvery(
    [
      actionTypes.GET_DETAILS,
      isSuccess(actionTypes.MODIFY_DETAILS),
      isSuccess(sessionActionTypes.AUTHENTICATE_USER),
      isSuccess(sessionActionTypes.VERIFY_USER)
    ],
    mapEffect(getDetails, actionTypes.GET_DETAILS)
  ),
  takeLatest(actionTypes.MODIFY_DETAILS, mapEffect(modifyDetails))
];

function* getDetails() {
  let userId = yield select(state => state.root.userInfo.userid);
  if (process.env.FEATURE_ENDPOINT === "reseller") {
    let profileDetails = yield UserDetailsSdk.reseller();
    window.__langPref = profileDetails.langpref;
    return { parentInfo: profileDetails, profileDetails };
  }
  let parentInfo =
    process.env.FEATURE_ENDPOINT === "customer"
      ? yield UserDetailsSdk.reseller().catch(() => ({}))
      : {};

  let profileDetails = yield commonServices.getCustomerDetails(userId);
  window.__langPref = profileDetails.langpref;
  return { parentInfo, profileDetails };
}

function modifyDetails(action) {
  let { resellerid, pin, ...infoToBeUpdated } = action.payload;
  return services.modifyDetails(infoToBeUpdated);
}

modifyDetails.onSuccess = function*(action) {
  if (action.successMessage) {
    yield put(notificationActions.showNotification(action.successMessage));
  }
  action.onSuccess && action.onSuccess();
};

modifyDetails.onFailure = function*(action, error) {
  yield put(
    notificationActions.showNotification(error.message, notificationTypes.ERROR)
  );
};
