import { ErrorPage, MaintenancePage } from "common/maintenance";
import { showLoader } from "common/util";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Root from "./root";

const DevTools = lazy(() =>
  import(/* webpackChunkName: "devtools" */ "./devtools")
);

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ "./dashboard/route")
);
const ProfileRoute = lazy(() =>
  import(/* webpackChunkName: "my-profile" */ "./dashboard/routes/my-profile")
);
const HelpRoute = lazy(() =>
  import(/* webpackChunkName: "dashboard-help" */ "./dashboard/routes/help")
);

const Orders = lazy(() =>
  import(/* webpackChunkName: "list-orders" */ "./list-orders")
);
const OrderRequests = lazy(() =>
  import(
    /* webpackChunkName: "order-requests" */ "./list-orders/routes/requests"
  )
);
const OrderDetail = lazy(() =>
  import(/* webpackChunkName: "order-detail" */ "./order-management")
);
const OrderHistory = lazy(() =>
  import(/* webpackChunkName: "order-history" */ "./order-history")
);
const MySites = lazy(() =>
  import(/* webpackChunkName: "sites-mfe" */ "./order-management/my-sites")
);

const Invoices = lazy(() =>
  import(/* webpackChunkName: "invoices" */ "./invoices")
);
const CreateManualInvoice = lazy(() =>
  import(
    /* webpackChunkName: "create-manual-invoice" */ "./invoices/create-manual-invoice"
  )
);

const ViewSystemInvoice = lazy(() =>
  import(
    /* webpackChunkName: "view-system-invoice" */ "./invoices/routes/view-invoice/system-invoice"
  )
);
const ViewManualInvoice = lazy(() =>
  import(
    /* webpackChunkName: "view-manual-invoice" */ "./invoices/routes/view-invoice/manual-invoice"
  )
);

const ViewReceipt = lazy(() =>
  import(
    /* webpackChunkName: "view-receipt" */ "./invoices/routes/view-invoice/receipt"
  )
);

const ProSuiteGetStarted = lazy(() =>
  import(
    /* webpackChunkName: "pro-suite-get-started" */ "./settings/pro-suite/pro-invoices/get-started"
  )
);
const ProSuiteWelcome = lazy(() =>
  import(/* webpackChunkName: "pro-suite-welcome" */ "./settings/pro-suite")
);
const APISettings = lazy(() =>
  import(
    /* webpackChunkName: "api-settings" */ "./settings/pro-suite/api-settings"
  )
);
const PaymentGateways = lazy(() =>
  import(
    /* webpackChunkName: "pro-suite-payment-gateways" */ "./settings/pro-suite/payment-gateways"
  )
);
const PaymentGatewayStatus = lazy(() =>
  import(/* webpackChunkName: "pg-status" */ "./payment-gateway-status")
);

let conditionalRoutes = [];

function LoadingComponent(Component) {
  return props => (
    <Suspense fallback={showLoader}>
      <Component {...props} />
    </Suspense>
  );
}

if (process.env.FEATURE_ENDPOINT !== "reseller") {
  const Login = lazy(() => import(/* webpackChunkName: "login" */ "./login"));
  conditionalRoutes.push(<Route path="/login" key="login" component={Login} />);
}

if (["reseller", "bigrock"].includes(process.env.FEATURE_ENDPOINT)) {
  const Services = lazy(() =>
    import(/* webpackChunkName: "services" */ "./services")
  );
  conditionalRoutes.push(
    <Route path="/affiliate" key="affiliate" component={Services} exact />
  );
}

if (process.env.FEATURE_ENDPOINT !== "reseller") {
  const Legal = lazy(() =>
    import(/* webpackChunkName: "legal-agreements" */ "./legal-agreements")
  );
  conditionalRoutes.push(
    <Route path="/legal" key="legal" exact component={Legal} />
  );
}

if (process.env.FEATURE_HAS_CUSTOMERS) {
  const Customers = lazy(() =>
    import(
      /* webpackChunkName: "pro-suite-customers" */ "./settings/pro-suite/customers"
    )
  );
  const CustomerPanel = lazy(() =>
    import(/* webpackChunkName: "customer-panel" */ "./customer-panel")
  );
  const CustomerProfile = lazy(() =>
    import(
      /* webpackChunkName: "customer-profile" */ "./customer-panel/routes/customer-profile"
    )
  );
  const routes = [
    <Route
      path="/settings/pro-suite/customers"
      key="settings"
      component={Customers}
    />,
    <Route
      path="/customers/profile/:customerId"
      key="customer-profile"
      component={CustomerProfile}
    />,
    <Route path="/customers" key="customers" component={CustomerPanel} />
  ];
  conditionalRoutes = [...conditionalRoutes, ...routes];
}

if (process.env.FEATURE_ENDPOINT === "reseller") {
  const SupersiteSettings = lazy(() =>
    import(
      /* webpackChunkName: "ss-settings" */ "./settings/pro-suite/supersite"
    )
  );
  conditionalRoutes.push(
    <Route
      path="/settings/pro-suite/supersite"
      key="pro"
      component={SupersiteSettings}
      exact
    />
  );
}

if (process.env.FEATURE_ENDPOINT === "reseller") {
  const Welcome = lazy(() =>
    import(/* webpackChunkName: "welcome-page" */ "./welcome-page")
  );
  conditionalRoutes.push(
    <Route path="/welcome" key="welcome" component={Welcome} />
  );
}

if (process.env.FEATURE_AUTO_RENEWAL) {
  const BillingAgreementFlow = lazy(() =>
    import(
      /* webpackChunkName: "billing-agreement" */ "./billing-agreement-flow"
    )
  );
  conditionalRoutes.push(
    <Route
      path="/billing-agreement-flow"
      key="billing-agreement-flow"
      component={BillingAgreementFlow}
    />
  );
}

const TransactionsStatusAddFunds = lazy(() =>
  import(/* webpackChunkName: "add-funds" */ "./transaction-status/add-funds")
);
conditionalRoutes.push(
  <Route
    path="/transactions/status/add-funds"
    key="transactions-add-funds"
    component={TransactionsStatusAddFunds}
  />
);

if (process.env.FEATURE_ENDPOINT !== "customer") {
  const Transactions = lazy(() =>
    import(/* webpackChunkName: "transactions" */ "./transactions")
  );
  conditionalRoutes.push(
    <Route
      path="/transactions"
      key="transactions"
      component={LoadingComponent(Transactions)}
    />
  );
}

if (process.env.FEATURE_ENDPOINT === "reseller") {
  const MyShop = lazy(() =>
    import(/* webpackChunkName: "my-shop" */ "./my-shop")
  );
  conditionalRoutes.push(
    <Route
      path="/orders-requests"
      key="orderrequests"
      component={LoadingComponent(OrderRequests)}
      exact
    />,
    <Route
      path="/invoices/new"
      key="create-manual-invoice"
      component={CreateManualInvoice}
      exact
    />,
    <Route path="/my-shop" key="my-shop" component={MyShop} />,
    <Route
      path="/global-settings"
      key="global-settings"
      component={Invoices}
      exact
    />,
    <Route
      path="/settings/pro-suite"
      key="pro-suite"
      component={ProSuiteWelcome}
      exact
    />,
    <Route
      path="/settings/pro-suite/api-settings"
      key="pro-suite-customers"
      component={APISettings}
      exact
    />,
    <Route
      path="/settings/pro-suite/invoices"
      key="pro"
      component={ProSuiteGetStarted}
      exact
    />,
    <Route
      path="/settings/pro-suite/payment-gateways"
      key="payment-gateways"
      component={PaymentGateways}
    />
  );
}

if (process.env.FEATURE_ENDPOINT === "bigrock") {
  const Support = lazy(() =>
    import(/* webpackChunkName: "support" */ "./support")
  );
  const supportRoutes = [
    <Route path="/support" key="support" exact component={Support} />
  ];
  conditionalRoutes = [...conditionalRoutes, ...supportRoutes];
}

let finalRoute = [
  <Route
    path="/"
    exact
    key="/"
    render={() => (
      <Redirect
        to={
          process.env.FEATURE_ENDPOINT !== "reseller" ? "/login" : "/dashboard"
        }
      />
    )}
  />,
  <Route path="/tools" exact key="tools" component={DevTools} />,
  // Is this fine?
  process.env.FEATURE_ENDPOINT !== "reseller" && (
    <Route
      path="/customer"
      key="obox-customer"
      render={props => (
        <Redirect to={{ pathname: "/login", search: props.location.search }} />
      )}
    />
  ),
  <Route key="error-page" exact path="/error" component={ErrorPage} />,
  <Root key="root">
    <Switch>
      <Route path="/maintenance" exact component={MaintenancePage} />
      <Route
        path="/dashboard/profile"
        component={LoadingComponent(ProfileRoute)}
      />
      <Route path="/dashboard/help" component={LoadingComponent(HelpRoute)} />
      <Route path="/dashboard" component={LoadingComponent(Dashboard)} exact />
      <Route
        path="/orders/history/:domain/:product/:orderId?"
        component={OrderHistory}
      />
      <Route path="/orders/sites/:orderid/:productKey" component={MySites} />
      <Route
        path="/invoices/system/:invoiceId"
        component={ViewSystemInvoice}
        exact
      />
      <Route
        path="/invoices/receipt/:invoiceId"
        component={ViewReceipt}
        exact
      />
      <Route path="/orders/manage/:domain/:product" component={OrderDetail} />
      <Route path="/orders" key="order" component={Orders} exact />
      <Route path="/payment-gateway-status" component={PaymentGatewayStatus} />
      {conditionalRoutes}
      <Route path="/invoices/:invoiceId" component={ViewManualInvoice} exact />
      <Route path="/invoices" component={Invoices} exact />
      <Route path="/receipts" component={Invoices} exact />
      <Route render={() => <Redirect to="/dashboard" />} />
    </Switch>
  </Root>
].filter(Boolean);

export default finalRoute;
