import {
  BrandConfiguration,
  BrandConfigurationOperations
} from "common/brand-configuration";
import { actions as legalActions } from "common/legal-agreements/ducks";
import { fonts } from "common/styles";
import { css } from "linaria";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

let copyright = css`
  font-family: ${fonts.OpenSans};
  font-size: 11px;
  text-align: center;
  color: #9ca5b1;
  padding-bottom: 0;
  margin-bottom: 20px;
  line-height: 1;

  & > a {
    color: #9ca5b1;
    border-bottom: 1px #9ca5b1 solid;
    margin-left: 15px;
    line-height: 1;
    padding-bottom: 2px;
    display: inline-block;
    position: relative;

    &:before {
      border-left: 1px #9ca5b1 solid;
      content: "";
      position: absolute;
      left: -8px;
      height: 100%;
    }

    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: #9ca5b1;
    }
  }
`;

let copyrightContainer = css`
  margin-top: 20px;
`;

type FooterContentProps = { isMaintenanceOn: boolean; companyName: string };

const FooterContent = ({
  isMaintenanceOn,
  companyName
}: FooterContentProps) => {
  const role = useSelector(state => state.root.userInfo.role);
  const { t } = useTranslation();
  const text = {
    legal: t("LEGAL_AGREEMENTS", "Legal Agreements"),
    privacyPolicy: t("PRIVACY_POLICY", "Privacy Policy"),
    ccpa: t("DO_NOT_SELL_MY_INFO", "Do Not Sell My Personal Information")
  };
  const dispatch = useDispatch();
  let brandConfiguration = useSelector(state =>
    BrandConfigurationOperations.findById(state.root.userInfo.parentid)
  );
  if (isMaintenanceOn) {
    return (
      <div className={`push--bottom wp-footer ${copyrightContainer}`}>
        <p className={copyright}>
          &copy; {new Date().getFullYear()} |{" "}
          {t("ALL_RIGHTS_RESERVED", "All Rights Reserved")}
        </p>
      </div>
    );
  }
  let urls: BrandConfiguration["urls"]["compliance"] = {
    legal: "/legal",
    privacyPolicy: "/legal"
  };
  if (brandConfiguration) {
    urls = brandConfiguration.urls.compliance;
  }
  let links = [];
  if (role === "reseller") {
    links.push(
      { href: urls.legal, text: text.legal },
      { href: urls.privacyPolicy, text: text.privacyPolicy }
    );
    if (urls.ccpa) {
      links.push({ href: urls.ccpa, text: text.ccpa });
    }
  }
  if (process.env.FEATURE_ENDPOINT === "customer") {
    links.push(
      { href: urls.legal, text: text.legal },
      {
        href: urls.privacyPolicy,
        text: text.legal,
        onClick() {
          dispatch(legalActions.setAgreementViewStatus("privacy"));
        }
      }
    );
  }
  return (
    <p className={copyright}>
      &copy; {new Date().getFullYear()}{" "}
      {role === "reseller" ? "ResellerClub" : companyName}.{" "}
      {t("ALL_RIGHTS_RESERVED", "All Rights Reserved")}
      {links.map(({ text, onClick, href }, index) =>
        href.startsWith("/") ? (
          <Link key={`${href}-${index}`} to={href} onClick={onClick}>
            {text}
          </Link>
        ) : (
          <a
            key={`${href}-${index}`}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        )
      )}
    </p>
  );
};

export default FooterContent;
