import { useCustomReducer } from "common/reducer";
import React from "react";
import { invoicesReducer } from "./invoices-reducer";

type TransactionState = "pending" | "paid" | "bad_debt" | "cancel" | "overdue";

export type InvoiceDetails = {
  invoiceId: number;
  transactionId: number;
  customInvoice: any;
  state: TransactionState;
  creationTime?: string;
  creationDate?: string;
  orderDetails: any;
  pendingTransactionDetails: any;
  type?: "custom_invoice" | "invoice";
};

export type ReceiptDetails = {
  transactionId: number;
  description: string;
  state: TransactionState;
  customerName: string;
  company: string;
};

type InvoicesTypes = {
  pageCount?: number;
  totalInvoices?: number;
};
export type InvoiceStore = {
  invoices: {
    manualInvoices: {
      [invoiceId: number]: InvoiceDetails;
    };
    systemInvoices: {
      [invoiceId: number]: InvoiceDetails;
    };
    customerInvoices: {
      [customerId: number]: {
        [invoiceId: number]: InvoiceDetails;
      };
    };
  };
  manualInvoices: {
    pending?: InvoicesTypes;
    paid?: InvoicesTypes;
    // eslint-disable-next-line camelcase
    bad_debt?: InvoicesTypes;
    overdue?: InvoicesTypes;
    cancel?: InvoicesTypes;
    selectedFilters?: string[];
    totalInvoices: number;
    searchFilter: {
      searchText: string;
      searchPageCount: number;
    };
    dateFilterRange: {
      from: string;
      to: string;
      dateFilterPageCount: number;
    };
  };
  systemInvoices: {
    pending?: InvoicesTypes;
    paid?: InvoicesTypes;
    totalInvoices: number;
    selectedFilters?: string[];
    searchFilter: {
      searchText: string;
      searchPageCount: number;
    };
    dateFilterRange: {
      from: string;
      to: string;
      dateFilterPageCount: number;
    };
  };
  customerInvoices: {
    [customerId: number]: {
      manualInvoices?: {
        pageCount?: number;
        totalInvoices?: number;
      };
      systemInvoices?: {
        pageCount?: number;
        totalInvoices?: number;
      };
    };
  };
  selectedTab: "all" | "manual" | "system";
  receipts: {
    totalReceipts: number;
    list: {
      [invoiceId: number]: ReceiptDetails;
    };
    pageCount?: number;
    searchFilter: { searchText: string; searchPageCount: number };
    dateFilterRange: { from: string; to: string; dateFilterPageCount: number };
  };
};

export let invoicesInitialState: InvoiceStore = {
  invoices: {
    manualInvoices: {},
    systemInvoices: {},
    customerInvoices: {}
  },
  systemInvoices: {
    selectedFilters: [],
    totalInvoices: 0,
    searchFilter: {
      searchText: "",
      searchPageCount: 0
    },
    dateFilterRange: {
      from: "",
      to: "",
      dateFilterPageCount: 0
    }
  },
  manualInvoices: {
    selectedFilters: [],
    totalInvoices: 0,
    searchFilter: {
      searchText: "",
      searchPageCount: 0
    },
    dateFilterRange: {
      from: "",
      to: "",
      dateFilterPageCount: 0
    }
  },
  customerInvoices: {},
  selectedTab: "all",
  receipts: {
    totalReceipts: 0,
    list: {},
    pageCount: 0,
    searchFilter: { searchText: "", searchPageCount: 0 },
    dateFilterRange: { from: "", to: "", dateFilterPageCount: 0 }
  }
};

type InvoicesContext = {
  invoicesStore?: InvoiceStore;
  manageInvoicesStore: {
    addNewPage?: ({
      type,
      invoices,
      invoicesTypes,
      searchText,
      selectedFilters,
      dateFilterRange
    }: {
      type: "manual" | "system" | "receipt";
      invoices: {
        [invoiceId: number]: InvoiceDetails;
      };
      invoicesTypes: any;
      searchText: string;
      selectedFilters: string[];
      dateFilterRange: { from?: string; to?: string };
    }) => {};
    updateNewPage?: ({
      type,
      invoices,
      invoicesTypes
    }: {
      type: "manual" | "system" | "receipt";
      invoices: {
        [invoiceId: number]: InvoiceDetails;
      };
      invoicesTypes: any;
    }) => {};
    addCustomerPage?: ({
      invoices,
      customerId,
      totalInvoices
    }: {
      invoices: {
        [invoiceId: number]: InvoiceDetails;
      };
      totalInvoices: {
        manual: number;
        system: number;
      };
      customerId: string;
    }) => {};
    updateNewCustomerPage?: ({
      invoices,
      customerId
    }: {
      invoices: {
        [invoiceId: number]: InvoiceDetails;
      };
      customerId: string;
    }) => {};
    setPageCount?: ({
      invoiceType,
      searchText,
      selectedFilters,
      dateFilterRange
    }: {
      invoiceType: string;
      searchText: string;
      selectedFilters: string[];
      dateFilterRange: { from?: string; to?: string };
    }) => {};
    setAllCustomerInvoicesCount?: ({
      customerId
    }: {
      customerId: string;
    }) => {};
    createNewInvoice?: ({ invoiceDetails }: { invoiceDetails: any }) => {};
    editInvoiceState?: ({
      previousState,
      invoice
    }: {
      previousState: InvoiceDetails["state"];
      invoice: InvoiceDetails;
    }) => {};
    updateInvoiceById?: () => {};
    updateSystemInvoiceById?: (invoice: Object) => {};
    updateReceiptById?: () => {};
    updateOrderDetails?: () => {};
    updatePendingTransactionDetails?: () => {};
    setSelectedTab?: ({
      tabName
    }: {
      tabName: InvoiceStore["selectedTab"];
    }) => {};
  };
};

export let ListInvoices = React.createContext<InvoicesContext>({
  invoicesStore: invoicesInitialState,
  manageInvoicesStore: {}
});

export const ListInvoicesContext = ({ children }) => {
  let [invoicesStore, manageInvoicesStore] = useCustomReducer(
    invoicesReducer,
    invoicesInitialState
  );

  return (
    <ListInvoices.Provider
      value={{
        invoicesStore,
        manageInvoicesStore
      }}
    >
      {children}
    </ListInvoices.Provider>
  );
};
