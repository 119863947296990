import { mapEffect } from "map-effect";
import { all, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import services from "./services";

export default [
  takeEvery(
    actionTypes.UPDATE_CHILD_NAMESERVERS,
    mapEffect(onUpdateChildNameServers)
  )
];

const doesNameServerExist = (nameServers = [], givenNameServer) => {
  const filteredNameServerList = nameServers.find(
    nameServer =>
      nameServer.hostname === givenNameServer.hostname &&
      nameServer.ip === givenNameServer.ip
  );
  return !!filteredNameServerList;
};

const isNSPresent = cns => !(cns.hostname.length === 0 && cns.ip.length === 0);

const groupByHostname = (cns, { hostname, ip }) => ({
  ...cns,
  [hostname]: cns[hostname] ? [...cns[hostname], ip] : [ip]
});

function* onUpdateChildNameServers(action) {
  let { oldFormData, newFormData, orderid } = action;

  oldFormData = oldFormData.filter(isNSPresent);
  newFormData = newFormData.nameServers.filter(isNSPresent);

  const newlyAddedChildNameServers = newFormData.filter(
    nameServer => !doesNameServerExist(oldFormData, nameServer)
  );

  const deleteChildNameServers = oldFormData.filter(
    nameServer => !doesNameServerExist(newFormData, nameServer)
  );

  yield all(
    Object.entries(
      newlyAddedChildNameServers.reduce(groupByHostname, {})
    ).map(([cns, ip]) => services.addNameServer(orderid, { cns, ip }))
  );

  yield all(
    Object.entries(
      deleteChildNameServers.reduce(groupByHostname, {})
    ).map(([cns, ip]) => services.deleteNameServer(orderid, { cns, ip }))
  );

  return newFormData;
}
