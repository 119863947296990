import storage from "@faizaanceg/pandora";
import { Syringe } from "@faizaanceg/syringe";
import { isSuccess } from "common/api-status/utils";
import { BrandConfigurationOperations } from "common/brand-configuration";
import { h } from "common/h";
import { createHash } from "common/hash";
import history from "common/history";
import { actions as SmartcartActions } from "common/orders/ducks";
import { parseStringAsBoolean } from "common/parse-string";
import { ConsentSdk } from "common/sdk/consents";
import { User } from "common/sdk/user";
import { ROUTE_OPTION, webProRoutes } from "common/side-bar/constants";
import { SmartCartSdk } from "common/smartcart/sdk";
import { addQueryParamsToUrl, getOBLogoutUrl, parseSearch } from "common/urls";
import { openPage } from "common/util";
import { set } from "lodash";
import { mapEffect } from "map-effect";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { actionTypes, actions, selector } from "./ducks";
import { services } from "./services";

export const rootSagas = [
  takeEvery(actionTypes.AUTHENTICATE_USER, onAuthenticateCustomer),
  takeEvery(actionTypes.BRANDING_DETAILS_REQUEST, onBrandingDetails),
  takeLatest(actionTypes.VERIFY_USER, onVerifyUser),
  takeEvery(actionTypes.GET_SESSION, mapEffect(services.getSession)),
  takeEvery(actionTypes.LOGOUT_REQUEST, onLogout),
  takeEvery(actionTypes.GO_TO_CONTROL_PANEL, onSwitchPanel),
  takeEvery(actionTypes.GSUITE_CONSENT_SHOW, onGsuiteConsentShow),
  takeEvery(isSuccess(actionTypes.AUTHENTICATE_USER), onPostLogin),
  takeEvery(actionTypes.PER_CUSTOMER_PRICING_REQUEST, onPerCustomerPricing)
];

const SiteManager = Syringe.inject("siteManager");

function* onPostLogin(session) {
  if (process.env.FEATURE_ENDPOINT === "bigrock") {
    window.customerid = session.userid;
    window.dataLayer?.push({ "customer-id": session.userid });
    const userConstraints = Syringe.inject("userConstraints");
    if (userConstraints.useConsolidatedPricing) {
      yield call(
        mapEffect(() => getPricingFromOB(session.userid)),
        { type: actionTypes.CONSOLIDATED_PRICING_REQUEST }
      );
    }
  } else {
    let id = process.env.FEATURE_ENDPOINT + "id";
    window[id] = yield createHash(session.userid).catch(() => session.userid);
  }
  yield call(mapEffect(services.getActiveProductsList), {
    type: actionTypes.ACTIVE_PRODUCTS_LIST
  });
  yield call(mapEffect(services.getProductMetadata), {
    type: actionTypes.PRODUCT_METADATA
  });
}

function* onGsuiteConsentShow(action) {
  try {
    const hasGSuite = yield services.hasGSuite(action.payload.userid);
    if (hasGSuite) {
      const consentList = yield ConsentSdk.fetch(["gsuite-consent"]);
      const hasGivenConsent = consentList.consent.find(
        _ => _.consentName === "gsuite-consent"
      );
      if (!hasGivenConsent) {
        yield put(actions.gsuiteConsentShowSuccess({ useFullScreen: true }));
      }
    }
  } catch (error) {
    yield put(actions.gsuiteConsentShowFailure(error));
  }
}

function* onSwitchPanel(action) {
  try {
    let autologinOptions =
      process.env.FEATURE_ENDPOINT === "bigrock"
        ? { role: "customer", "force-cp": true }
        : { role: "reseller" };
    const { TOKEN } = yield User.token();
    const parentbrandingurl = storage.get(
      "parentbrandingurl",
      window.location.hostname
    );
    let logoutUrl = addQueryParamsToUrl(
      `https://${parentbrandingurl}/servlet/AutoLoginServlet`,
      {
        ...autologinOptions,
        orderid: action.payload["order-id"],
        userLoginId: TOKEN,
        "view-name": action.payload["view-name"]
      }
    );
    yield put(actions.switchPanelSuccess());
    yield call(onLogout, { logoutUrl });
  } catch (error) {
    yield put(actions.switchPanelFailure(error));
  }
}

function* onLogout({ logoutUrl = getOBLogoutUrl() }) {
  try {
    if (process.env.FEATURE_ENDPOINT === "bigrock") {
      const hideWalkthrough = localStorage.getItem("no-walk-through") === "1";
      localStorage.clear();
      if (hideWalkthrough) {
        localStorage.setItem("no-walk-through", "1");
      }
      /** @type {import("common/brand-configuration").BrandConfiguration} */
      const brandConfiguration = yield select(state =>
        BrandConfigurationOperations.findById(state.root.userInfo.parentid)
      );
      let storeFrontUrl = brandConfiguration?.urls?.storefront?.supersiteUrl;
      if (storeFrontUrl) {
        yield call(services.logoutFromSuperSite, storeFrontUrl);
      }
    } else {
      storage.clear();
      storage.set("disableAPISideEffects", true, { ttl: 1000 * 60 * 5 });
    }
    yield services.logout();
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield put(actions.logoutFailure(error));
  }
  window.location.replace(logoutUrl);
}

function* onPerCustomerPricing({ customerId, productKey }) {
  const userConstraints = Syringe.inject("userConstraints");
  if (userConstraints.useConsolidatedPricing) {
    return;
  }
  const customerPricing = yield services.getPerCustomerPricing(
    customerId,
    productKey
  );
  const role = yield select(state => state.root.userInfo.role);
  if (role !== "reseller") {
    yield put(actions.storeFallbackPrice(customerPricing));
  }
  yield put(
    actions.getPerCustomerPricingSuccess({ customerId, customerPricing })
  );
}

function* onBrandingDetails({ location }) {
  try {
    const parent = yield SiteManager.details();
    if (parent?.url) {
      storage.set("parentbrandingurl", parent.url, {
        shouldPersist: true
      });
    }
    yield put(actions.getBrandingDetailsSuccess(parent));
  } catch (error) {
    yield put(actions.getBrandingDetailsFailure(error));
  }
  yield put(actions.verifyUser(location));
}

async function getPricingFromOB(userId) {
  let promoDetails = await services.promoDetails();
  let bulkPricing = await services.getBulkCustomerPricing(userId);
  for (const promo of Object.values(promoDetails)) {
    let { actiontype, isactive, customerprice, period, productkey } = promo;
    if (parseStringAsBoolean(isactive)) {
      set(bulkPricing, [productkey, actiontype, period], Number(customerprice));
    }
  }
  return bulkPricing;
}

function* onAuthenticateCustomer(action) {
  try {
    const {
      payload: { isPasswordVisible: notNeeded, ...values }
    } = action;
    const session = yield services.performCustomerLogin(values);
    yield put(actions.authenticateSuccess(session));
    let {
      next: redirectionPath = ROUTE_OPTION[webProRoutes.HOME]
    } = parseSearch(action.location.search);
    openPage(redirectionPath);
  } catch (error) {
    yield put(actions.authenticateFailure(error));
  }
}

function* onVerifyUser({ payload }) {
  let { performSideEffects = true } = payload;

  try {
    if (performSideEffects) {
      if (process.env.FEATURE_ENDPOINT !== "reseller") {
        const { pathname = "", search } = payload;
        let { next: nextPage = ROUTE_OPTION[webProRoutes.HOME] } = parseSearch(
          search
        );
        const { isLoggedIn } = yield select(selector);
        if (isLoggedIn && pathname === ROUTE_OPTION[webProRoutes.LOGIN]) {
          yield put(actions.verifyUserSuccess({}));
          openPage(nextPage);
          return;
        }
      } else {
        let redirectUrl = storage.get("redirect-url");
        storage.remove("redirect-url");
        openPage(redirectUrl);
      }
    }
    const session = yield services.getSession();
    let defaultCustomer = {};
    if (process.env.FEATURE_ENDPOINT === "reseller") {
      defaultCustomer = yield services.getDefaultCustomer();
    }
    yield call(onPostLogin, session, performSideEffects);
    if (performSideEffects) {
      yield call(startTracking, session);
    }
    yield put(actions.verifyUserSuccess({ ...session, defaultCustomer }));
    if (performSideEffects) {
      if (session.role === "reseller") {
        storage.set("brandingurl", session.userDetails.brandingurl);
        window.isOldReseller = session.userDetails.isOldReseller;
        if (!window.isOldReseller) {
          yield put(actions.gsuiteConsentShow(session));
        }
      }
      if (session.role === "reseller") {
        const [health] = yield SmartCartSdk.health();
        yield put(
          actions.updateSmartCartHealth(health?.status === 200 ? "up" : "down")
        );
        if (health?.status === 200) {
          yield put(
            SmartcartActions.getOrderRequests("orderequests", false, false)
          );
        }
      }
    }
  } catch (error) {
    // TODO: Maybe we need to check if error was due to session expiry or not
    yield put(actions.verifyUserFailure(error));
    let { pathname } = payload;

    if (performSideEffects) {
      if (process.env.FEATURE_ENDPOINT === "reseller") {
        let logoutUrl = getOBLogoutUrl();
        storage.clear();
        if (pathname && pathname !== ROUTE_OPTION[webProRoutes.HOME]) {
          storage.set("redirect-url", pathname, {
            ttl: 1000 * 60 * 5,
            shouldPersist: true
          });
        }
        window.location.replace(logoutUrl);
      } else {
        let redirectionParams = {
          pathname: ROUTE_OPTION[webProRoutes.LOGIN]
        };

        if (pathname !== ROUTE_OPTION[webProRoutes.LOGIN] && pathname !== "/") {
          const searchPath = `?next=${encodeURIComponent(pathname)}`;
          const queryObject = {
            next: pathname
          };
          redirectionParams = {
            ...redirectionParams,
            search: searchPath,
            query: queryObject
          };
        }
        history.replace({
          ...payload,
          ...redirectionParams
        });
      }
    }
  }
}

function* startTracking({ userDetails }) {
  let isEligibleForGDPR = yield services.isEligibleForGDPR(userDetails.country);
  if (!isEligibleForGDPR) {
    window.hj =
      window.hj ||
      function() {
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    let hjSettings = { hjid: process.env.FEATURE_TRACKING_HJID, hjsv: 6 };
    window._hjSettings = hjSettings;
    document.head.appendChild(
      h("script", {
        src: `https://static.hotjar.com/c/hotjar-${hjSettings.hjid}.js?sv=${hjSettings.hjsv}`,
        async: 1
      })
    );
  }
}
