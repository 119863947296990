import ErrorBoundary from "common/errors/boundary";
import history from "common/history";
import { ListInvoicesContext } from "common/invoices/hooks/list-invoices-context";
import { ErrorPage } from "common/maintenance";
import ScrollToTop from "common/scroll-top";
import { StorageProvider } from "common/storage/hooks";
import { showLoader } from "common/util";
import React, { Suspense } from "react";
import { Router, Switch } from "react-router-dom";
import finalRoute from "./routes";

class AppContainer extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <StorageProvider>
        <ListInvoicesContext>
          <div style={{ height: "100%" }}>
            <Suspense fallback={showLoader}>
              <Router history={history}>
                <ScrollToTop>
                  <ErrorBoundary fallback={<ErrorPage />}>
                    <Switch>{finalRoute}</Switch>
                  </ErrorBoundary>
                </ScrollToTop>
              </Router>
            </Suspense>
          </div>
        </ListInvoicesContext>
      </StorageProvider>
    );
  }
}

export default AppContainer;
