/**
 *
 * @param {import("react-redux").DefaultRootState} state
 */
export function getProductMetadata(state) {
  return state.root.productMetadata;
}

/**
 *
 * @param {import("constants/ProductKeyMapping").ProductKeys} productKey
 * @param {"domorder" | "hosting"} productCategory
 * @returns { {actions: Record<string, any>;} | null }
 */
export function getMetadataFor(productKey, productCategory) {
  return (/** @type {import("react-redux").DefaultRootState} */ state) => {
    if (productCategory !== "domorder") {
      return null;
    }
    let metadata = getProductMetadata(state);
    let productMetdata = metadata.domorder.domains[productKey];
    return productMetdata ?? null;
  };
}
