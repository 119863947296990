import api from "api";
import { useAPI, useAPIEffect } from "common/hooks/use-api";
import endpoints from "constants/endpoints";
import { useCallback, useEffect, useRef, useState } from "react";

let effectLoading = Symbol.for("start");

export function isLoading(data) {
  return effectLoading === data;
}

export function useSaveTaxPayerId() {
  let [apiStatus, , addEffectHandlers] = useAPIEffect(useState(null));
  let saveTaxPayerId = useCallback(
    (countryCode, taxId) =>
      addEffectHandlers(() =>
        api.put(`${endpoints.zuulOrderBoxV2}tax-details/me/${countryCode}`, {
          taxId
        })
      ),
    [addEffectHandlers]
  );

  return [apiStatus, saveTaxPayerId];
}

export function useTaxPayerIdByCountryCode(countryCode) {
  let [taxPayerId, setTaxPayerId] = useState({});

  let [status, apiActions] = useAPI(
    countryCode && `${endpoints.zuulOrderBoxV2}tax-details/me/${countryCode}`,
    setTaxPayerId
  );
  return [status, taxPayerId, apiActions];
}
