import storage from "@faizaanceg/pandora";
import APIStatus from "common/api-status";
import { isFailure, isSuccess } from "common/api-status/utils";
import { actionTypes as autoRenewActionTypes } from "common/auto-renewal/ducks";
import { EmailDiscountBanner } from "common/discount-view/banner";
import WPBuyModal from "common/modal/buy-modal";
import { renewActionTypes } from "common/orders/ducks";
import { onReload } from "common/reloadable/ducks";
import { useStorage } from "common/storage/hooks";
import { colors, fonts } from "common/styles";
import {
  getTaxAmount,
  getTaxInfo,
  isIntegratedWithExpose,
  isValidNumber
} from "common/util";
import { withUserPayments } from "common/with-user-payments";
import ProductKeyMapping from "constants/ProductKeyMapping";
import { getSimpleNameFromProductKey } from "constants/index";
import { Layouts as PaymentStatusPageLayouts } from "constants/layout";
import { css } from "linaria";
import { styled } from "linaria/react";
import { isEmpty } from "lodash";
import { ModalHandlerProps } from "modals/modal";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { isRenewalAllowed } from "./addons/clear-conditions";
import {
  getBodyParams,
  getCostStrategy,
  getDomainName,
  getRenewQuery,
  getRenewalPrice,
  getSelectedTenure
} from "./constants";
import "./modals.scss";
import RenewalModalRows from "./renewal-modal-rows";

export const renewalActions = {
  openRenewalModal: (meta = {}) => ({
    type: renewActionTypes.RENEWAL_OPEN,
    meta
  })
};

let RenewalTitle = styled.div`
  font: 700 10px/1.5 ${fonts.BrandonGrotesque};
  letter-spacing: 0.5px;
  color: ${colors.gray.gray700};
  text-transform: uppercase;
`;

export let RenewalGridRows = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 300px auto;
  &[data-renewal-is-dashboard="true"] {
    grid-template-columns: 300px 180px auto 10px;
    grid-gap: 20px;
  }
`;

type RenewalModalProps = {
  renewalDataByOrderID?: Record<string, any>;
  removeOrderFromRenewalData?: Function;
  taxinfo?: any;
  resetOrderSelection?: Function;
  openRenewal?: Function;
  forDashboard?: boolean;
  currencySymbol?: string;
  domainname?: string;
  isPaymentsAllowedForUser: boolean;
};

const RenewalModal = ({
  renewalDataByOrderID = {},
  onHide,
  taxinfo,
  resetOrderSelection,
  removeOrderFromRenewalData,
  openRenewal,
  forDashboard = true,
  show,
  currencySymbol,
  domainname,
  isPaymentsAllowedForUser
}: RenewalModalProps & ModalHandlerProps) => {
  const { t } = useTranslation();
  const role = useSelector(state => state.root.userInfo.role);
  const [deferredOrders, setDeferredOrders] = useStorage("deferred-orders", {});
  const [modifiedRenewalDetails, setModifiedRenewalDetails] = useState(
    renewalDataByOrderID
  );

  const isRenewalAllowedFor = Object.values(modifiedRenewalDetails).every(
    isRenewalAllowed
  );

  const onAddonsSelected = (
    orderid,
    addonName,
    addonPrice,
    param,
    doNotConsiderBasePrice = false,
    customerAddonPrice
  ) => {
    setModifiedRenewalDetails(modifiedRenewalDetails => ({
      ...modifiedRenewalDetails,
      [orderid]: {
        ...(modifiedRenewalDetails?.[orderid] ?? {}),
        doNotConsiderBasePrice,
        addonDetails: {
          ...getAddonDetails(orderid),
          ...modifiedRenewalDetails?.[orderid]?.addonDetails,
          addons: {
            ...(getAddonDetails(orderid)?.addons ?? {}),
            ...modifiedRenewalDetails?.[orderid]?.addonDetails?.addons,
            [addonName]: {
              ...renewalDataByOrderID[orderid].addonDetails.addons[addonName],
              totalPrice: addonPrice,
              totalCustomerPrice: customerAddonPrice,
              param
            }
          }
        }
      }
    }));
  };

  const setRenewalPricing = (orderid, value) =>
    setModifiedRenewalDetails({
      ...modifiedRenewalDetails,
      [orderid]: {
        ...(modifiedRenewalDetails?.[orderid] ?? {}),
        selectedTenure: parseInt(value, 10),
        price:
          renewalDataByOrderID[orderid].pricing[value] * parseInt(value, 10),
        customerPrice:
          renewalDataByOrderID[orderid]?.customerPricing[value] *
          parseInt(value, 10)
      }
    });

  const getAddonDetails = orderid =>
    modifiedRenewalDetails?.[orderid]?.addonDetails ??
    renewalDataByOrderID?.[orderid]?.addonDetails ??
    {};

  const removeOrderFromRenewal = orderid => {
    removeOrderFromRenewalData(orderid);
    if (Object.keys(renewalDataByOrderID).length === 0) {
      resetStateAndCloseModal(onHide);
    }
  };

  // to show loader until all pricing calls have returned
  const isFetchingPricingData = Object.keys(renewalDataByOrderID).reduce(
    (isFetching, orderId) =>
      isEmpty(renewalDataByOrderID[orderId].pricing) && isFetching,
    true
  );

  const isInRestorationPhase = orderid =>
    renewalDataByOrderID?.[orderid]?.restoreDetails?.isInRestorationPhase ??
    false;

  const getAddonParams = orderid => {
    const {
      [orderid]: { addonDetails: { addons = {} } = {} }
    } = renewalDataByOrderID;

    return Object.entries(addons).reduce(
      (addonParams, [addonName, addon]: any) => ({
        ...addonParams,
        [addon.type]:
          modifiedRenewalDetails?.[orderid]?.addonDetails?.addons?.[addonName]
            ?.param ?? addon.param
      }),
      {}
    );
  };

  const getActionDetails = () => {
    const ordersForRenewal = Object.keys(renewalDataByOrderID)
      .filter(orderId => !isInRestorationPhase(orderId))
      .map(orderid => getDomainName(orderid, renewalDataByOrderID));

    const ordersForRestoration = Object.keys(renewalDataByOrderID)
      .filter(isInRestorationPhase)
      .map(orderid => getDomainName(orderid, renewalDataByOrderID));

    const renewAction =
      ordersForRenewal.length > 0 ? `Renew ${ordersForRenewal.join(", ")}` : "";

    const restoreAction =
      ordersForRestoration.length > 0
        ? `Restore ${ordersForRestoration.join(", ")}`
        : "";

    const action =
      renewAction.length > 0 && restoreAction.length > 0
        ? `${renewAction} and ${restoreAction}`
        : renewAction || restoreAction;
    return action;
  };

  const getActionMetaData = () => {
    return Object.keys(renewalDataByOrderID).map(orderid => {
      const {
        recurring,
        orderDetails: { keyToGetProductDetails = "", ...orderDetails } = {}
      } = renewalDataByOrderID[orderid] || {};
      const price = getRenewalPrice(
        orderid,
        renewalDataByOrderID,
        modifiedRenewalDetails
      )?.resellerPrice;
      const customerPrice =
        getRenewPriceWithAddons(orderid)?.customerAddOnPrice ?? 0;
      if (keyToGetProductDetails === "") {
        return {};
      }
      let endpoint, defaultErrorMessage;
      let defaultSuccessMessage = isPaymentsAllowedForUser
        ? "Renewal of your order was successful"
        : "Request for renewal was successfully placed";
      let { endpoints } = ProductKeyMapping[keyToGetProductDetails].meta;
      if (keyToGetProductDetails === "domorder") {
        defaultSuccessMessage = isInRestorationPhase(orderid)
          ? `We have initiated the process of restoring ${getDomainName(
              orderid,
              renewalDataByOrderID
            )}. It might take upto 5 days`
          : undefined;
        defaultErrorMessage = isInRestorationPhase(orderid)
          ? `Some error occurred in trying to restore the domain ${getDomainName(
              orderid,
              renewalDataByOrderID
            )}`
          : undefined;
        endpoint = isInRestorationPhase(orderid)
          ? endpoints.restore
          : endpoints.renew;
      } else {
        endpoint = endpoints.renew;
      }
      let addonParams = getAddonParams(orderid);
      let shouldEnableAutoRenew = !isEmpty(deferredOrders[orderid]);
      let simpleName = getSimpleNameFromProductKey(keyToGetProductDetails);
      let tenure = getSelectedTenure(
        orderid,
        renewalDataByOrderID,
        modifiedRenewalDetails
      );
      return {
        key: `o${orderid}`,
        endpoint,
        method: isIntegratedWithExpose(simpleName) ? "PATCH" : "POST",
        actionTypes: {
          successActionType: isSuccess(renewActionTypes.RENEWAL_REQUEST),
          successActionPayload: {
            orderid,
            defaultMessage: defaultSuccessMessage
          },
          failureActionType: isFailure(renewActionTypes.RENEWAL_REQUEST),
          failureActionPayload: { orderid, defaultMessage: defaultErrorMessage }
        },
        pathParams: {
          orderid,
          ...addonParams,
          ...renewalDataByOrderID[orderid].orderDetails.params,
          selected_tenure: tenure
        },
        params: getRenewQuery({
          autoRenew: Boolean(shouldEnableAutoRenew || recurring),
          orderId: orderid,
          product: simpleName as any,
          productKey: keyToGetProductDetails,
          options: {
            restore: isInRestorationPhase(orderid),
            ...renewalDataByOrderID[orderid].orderDetails.params,
            ...addonParams
          },
          tenure,
          role
        }),
        meta: {
          ...orderDetails,
          price,
          customerPrice,
          currencySymbol,
          orderid
        },
        requestBody: getBodyParams(simpleName)
      };
    });
  };

  const getRenewPriceWithAddons = orderid =>
    getCostStrategy(orderid, renewalDataByOrderID).getRenewPriceWithAddons(
      modifiedRenewalDetails[orderid],
      renewalDataByOrderID[orderid],
      getSelectedTenure(orderid, renewalDataByOrderID, modifiedRenewalDetails)
    );

  const getTotalPrice = Object.keys(renewalDataByOrderID)
    .filter(orderid =>
      isValidNumber(
        getRenewalPrice(orderid, renewalDataByOrderID, modifiedRenewalDetails)
          ?.resellerPrice
      )
    )
    .reduce(
      (sum, orderid) =>
        sum + getRenewPriceWithAddons(orderid)?.resellerAddOnPrice,
      0
    );

  const customerPrice = Object.keys(renewalDataByOrderID)
    .filter(orderid =>
      isValidNumber(
        getRenewalPrice(orderid, renewalDataByOrderID, modifiedRenewalDetails)
          ?.customerPrice
      )
    )
    .reduce(
      (sum, orderid) =>
        sum + getRenewPriceWithAddons(orderid)?.customerAddOnPrice,
      0
    );

  const getTotalTax = Object.keys(renewalDataByOrderID)
    .filter(orderid =>
      isValidNumber(
        getRenewalPrice(orderid, renewalDataByOrderID, modifiedRenewalDetails)
          ?.resellerPrice
      )
    )
    .reduce(
      (sum, orderid) =>
        parseFloat(
          getTaxAmount(
            getRenewPriceWithAddons(orderid)?.resellerAddOnPrice,
            getTaxInfo(
              renewalDataByOrderID?.[orderid]?.orderDetails ?? {},
              taxinfo
            )
          )
        ) + sum,
      0
    );

  const resetStateAndCloseModal = onHide => {
    setModifiedRenewalDetails({});
    // eslint-disable-next-line no-unused-expressions
    resetOrderSelection?.();
    onHide();
  };

  const getRestoreOrderDetails = () => {
    const orderIds = Object.keys(renewalDataByOrderID ?? []);
    return {
      isShowRestoreModal:
        orderIds.length === 1 && isInRestorationPhase(orderIds[0]),
      restoreDetails: renewalDataByOrderID?.[orderIds[0]]?.restoreDetails ?? {}
    };
  };

  const displayDiscountBanner = () => {
    if (forDashboard) {
      return null;
    }
    const [discountDetails] = Object.values(renewalDataByOrderID).map(
      (_: any) => {
        let discount = _?.planDetails?.discount;
        return {
          discount,
          isDiscountAvailable: _.miscDetails?.isDiscountApplicable ?? false
        };
      }
    );
    return discountDetails?.isDiscountAvailable ? (
      <EmailDiscountBanner discount={discountDetails.discount} />
    ) : null;
  };

  const cost: any = getTotalPrice;
  const tax: any = getTotalTax;
  const { isShowRestoreModal, restoreDetails } = getRestoreOrderDetails();
  let noOfOrders = Object.keys(renewalDataByOrderID).length;
  let payButtonsInfo = {
    isPayButtonsDisabled: !isRenewalAllowedFor,
    layoutInfo: {
      type: PaymentStatusPageLayouts.PRODUCT_INFO
    },
    startAndEndActionTypes: {
      endActionType: "gtm_renew_action_",
      endActionPayload: {
        track: {
          eventAction: `gtm_renew_purchase_`,
          attributes: {
            ordersArray: []
          }
        }
      }
    },
    actionDetails: {
      key: "orenewal",
      value: getActionMetaData(),
      action: getActionDetails()
    },
    hideCurrentModal: onHide
  };
  return (
    <APIStatus
      monitors={[
        [
          autoRenewActionTypes.SET_AUTO_RENEWAL,
          onReload(isSuccess(autoRenewActionTypes.SET_AUTO_RENEWAL))
        ]
      ]}
      render={apiStatus => (
        <WPBuyModal
          apiStatus={apiStatus}
          alignCenter={!forDashboard}
          show={show}
          width={isShowRestoreModal && 550}
          onHide={(isFlowProgressing: boolean) => {
            resetStateAndCloseModal(onHide);
            if (!isFlowProgressing) {
              storage.remove("deferred-orders");
            }
          }}
          title={
            isShowRestoreModal
              ? t(
                  "MY_SHOP_RESTORE_TITLE",
                  `Restore ${restoreDetails?.domainname}`,
                  { domainname: restoreDetails?.domainname }
                )
              : t("CONFIRM_RENEWAL", "CONFIRM RENEWAL")
          }
          subTotal={+cost.toFixed(2)}
          customerPrice={Number(customerPrice.toFixed(2)) ?? 0}
          assignedDomain={
            isShowRestoreModal
              ? t(
                  "MY_SHOP_RESTORE_TITLE",
                  `Restore ${restoreDetails?.domainname}`,
                  { domainname: restoreDetails?.domainname }
                )
              : forDashboard
              ? `${noOfOrders} ${
                  noOfOrders === 1 ? t("ORDER", "Order") : t("ORDERS", "Orders")
                }`
              : domainname
          }
          payButtonsInfo={payButtonsInfo}
          renewTaxInfo={tax}
          goBackToPrevState={openRenewal}
          productkey={
            Object.values(renewalDataByOrderID)[0]?.orderDetails?.productkey
          }
        >
          {isShowRestoreModal ? (
            <div
              className={css`
                text-align: center;
                padding: 20px;
              `}
            >
              <Trans i18nKey="ODR_RENEWAL_ON_RESTORE">
                <div className="tcenter be-in-title">
                  RENEWAL ON RESTORATION
                </div>
                <div className="tcenter be-in-days">
                  {{ tenure: restoreDetails?.tenure }}
                </div>
                <div className="tcenter be-valid">
                  till {{ tenureSubText: restoreDetails?.tenureSubText }}
                </div>
              </Trans>
            </div>
          ) : (
            <>
              <div>{displayDiscountBanner()}</div>
              <div className="renewal-modal">
                <RenewalGridRows
                  data-renewal-is-dashboard={forDashboard}
                  style={{ padding: "26px 30px 0px" }}
                >
                  <RenewalTitle>{t("PRODUCT", "PRODUCT")}</RenewalTitle>
                  <RenewalTitle>{t("DURATION", "DURATION")}</RenewalTitle>
                  {forDashboard && (
                    <>
                      <RenewalTitle>
                        {t("RENEWAL_COST", "RENEWAL COST")}
                      </RenewalTitle>
                    </>
                  )}
                </RenewalGridRows>
                <RenewalModalRows
                  onHide={onHide}
                  renewalDataByOrderID={renewalDataByOrderID}
                  modifiedRenewalDetails={modifiedRenewalDetails}
                  setModifiedRenewalDetails={setRenewalPricing}
                  isFetchingPricingData={isFetchingPricingData}
                  isInRestorationPhase={isInRestorationPhase}
                  forDashboard={forDashboard}
                  getAddonDetails={getAddonDetails}
                  onAddonsSelected={onAddonsSelected}
                  removeOrderFromRenewal={removeOrderFromRenewal}
                  setDeferredOrders={setDeferredOrders}
                />{" "}
              </div>
            </>
          )}
        </WPBuyModal>
      )}
    />
  );
};

const mapStateToProps = (state, myProps) => {
  let { renewalDataByOrderID } = myProps;
  let { ordersByOrderID } = state.orders;
  let { currencySymbol, taxinfo } = state.wallet;
  return {
    currencySymbol,
    taxinfo,
    renewalDataByOrderID: Object.fromEntries(
      Object.entries(renewalDataByOrderID).map(
        ([orderId, renewalDetails]: any) => {
          let { recurring } = ordersByOrderID[orderId];
          return [orderId, { recurring, ...renewalDetails }];
        }
      )
    )
  };
};

export default withUserPayments(connect(mapStateToProps, null)(RenewalModal));
