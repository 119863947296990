import { parseStringAsBoolean } from "common/parse-string";
import {
  convertUNIXToDate,
  convertUTCTimestampToDate,
  differenceInDaysFromNow,
  getDate,
  getDuration,
  getFormattedDate,
  getIcon,
  getRemainingDaysFromNow,
  hasOrderExpired,
  isOrderExpiringIn30Days
} from "common/util";
import { OrderStatus } from "constants/index";
import ProductKeyMapping from "constants/ProductKeyMapping";
import { addYears, differenceInYears } from "date-fns";
import { parsers as manageTitan } from "widget-models/manage-titan/ducks";

const DefaultMetadata = { maxTenure: 10, minTenure: 1 };

export const helpers = {
  formatOrderList({
    orderid = "",
    creationtime: creationtimeInt = "",
    domainname = "",
    currentstatus = "",
    customerid = "",
    endtime: endtimeInt = "",
    deltime: deltimeInt = "",
    product_key: productkey = "",
    productKey = productkey,
    product_category: category,
    recurring,
    "product.productcategory": productCategory = "",
    raaVerificationStatus,
    pendingTransaction
  }) {
    const productDetailsKey =
      productCategory === "domorder" ? "domorder" : productKey;
    let expirydatetime = String(endtimeInt);
    let creationtime = String(creationtimeInt);
    let deletiondatetime = String(deltimeInt);
    const { productType = "", meta: productMeta = {} } =
      ProductKeyMapping[productDetailsKey] || {};
    return {
      product_category: category,
      productDetailsKey,
      domainname,
      duration: getDuration(creationtime, expirydatetime),
      durationDate: getDate(creationtime, expirydatetime),
      expiresIn: Math.abs(differenceInDaysFromNow(expirydatetime)),
      expiresOn: getFormattedDate(expirydatetime),
      createdOn: getFormattedDate(creationtime),
      deletesIn: getRemainingDaysFromNow(deletiondatetime),
      deletesOn: getFormattedDate(deletiondatetime),
      icon: getIcon(productKey),
      isExpired: hasOrderExpired(expirydatetime),
      isExpiring: isOrderExpiringIn30Days(expirydatetime),
      isActive: currentstatus.toLowerCase() === OrderStatus.ACTIVE,
      isInactive: currentstatus.toLowerCase() === OrderStatus.INACTIVE,
      isSuspended: currentstatus.toLowerCase() === OrderStatus.SUSPENDED,
      recurring: parseStringAsBoolean(recurring),
      currentstatus: currentstatus.replace(/\s+/g, ""),
      productType,
      meta: {
        ...productMeta,
        customerid,
        domainname,
        orderid,
        productcategory: productCategory,
        productCategory,
        productkey: productKey,
        productKey,
        currentstatus: currentstatus.replace(/\s+/g, ""),
        raaVerificationStatus,
        productDetailsKey,
        product_category: category,
        creationtime: getFormattedDate(creationtime),
        expirydatetime: getFormattedDate(expirydatetime),
        creationTimestamp: creationtime,
        expiryTimestamp: expirydatetime,
        deletesIn: getRemainingDaysFromNow(deletiondatetime),
        deletesOn: getFormattedDate(deletiondatetime),
        creationtimetimestamp: creationtime,
        deletiondatetime: deletiondatetime,
        expirydatetimetimestamp: expirydatetime
      },
      orderid,
      productcategory: productCategory,
      productCategory,
      productKey,
      status: currentstatus.toLowerCase(),
      pendingTransaction
    };
  },
  formatOboxOrderDetails(
    {
      orderid = "",
      creationtime = "",
      domainname = "",
      currentstatus = "",
      customerid = "",
      endtime: expirydatetime = "",
      productkey = "",
      productKey = productkey,
      productcategory: productCategory = "",
      raaVerificationStatus,
      recurring,
      is_free
    },
    { product_category, activity = [], deletesIn, deletesOn }
  ) {
    const productDetailsKey =
      productCategory === "domorder" ? "domorder" : productkey;

    const { productType = "", meta: productMeta = {} } =
      ProductKeyMapping[productDetailsKey] ?? {};
    return {
      product_category,
      productDetailsKey,
      domainname,
      duration: getDuration(creationtime, expirydatetime),
      durationDate: getDate(creationtime, expirydatetime),
      expiresIn: Math.abs(differenceInDaysFromNow(expirydatetime)),
      expiresOn: getFormattedDate(expirydatetime),
      createdOn: getFormattedDate(creationtime),
      deletesIn,
      deletesOn,
      icon: getIcon(productkey),
      isActive: currentstatus.toLowerCase() === OrderStatus.ACTIVE,
      isExpired: hasOrderExpired(expirydatetime),
      isExpiring: isOrderExpiringIn30Days(expirydatetime),
      isInactive: currentstatus.toLowerCase() === OrderStatus.INACTIVE,
      isSuspended: currentstatus.toLowerCase() === OrderStatus.SUSPENDED,
      isTrial: is_free,
      recurring: parseStringAsBoolean(recurring),
      currentstatus: currentstatus.replace(/\s+/g, ""),
      productType,
      meta: {
        ...productMeta,
        customerid,
        domainname,
        orderid,
        productcategory: productCategory,
        productCategory,
        productkey,
        productKey,
        currentstatus: currentstatus.replace(/\s+/g, ""),
        raaVerificationStatus,
        productDetailsKey,
        product_category,
        creationtime: getFormattedDate(creationtime),
        expirydatetime: getFormattedDate(expirydatetime),
        creationTimestamp: creationtime,
        expiryTimestamp: expirydatetime,
        deletesIn,
        deletesOn,
        creationtimetimestamp: creationtime,
        deletiondatetime: expirydatetime,
        expirydatetimetimestamp: expirydatetime
      },
      orderid,
      productcategory: productCategory,
      productCategory,
      productKey,
      status: currentstatus.toLowerCase(),
      activity
    };
  },
  getParams(orderDetails = {}, keyToGetProductDetails = "") {
    // Params for renewal call
    switch (keyToGetProductDetails) {
      case "eeliteus":
        return { type: "eelite", country: "us" };
      case "enterpriseemail":
        return { type: "enterpriseemail" };
      case "domorder": {
        const { endtime: exp_date = 0 } = orderDetails as any;
        return { exp_date };
      }
      case "titanmailindia":
      case "titanmailglobal": {
        return manageTitan.detailsParser(orderDetails);
      }
      case "resellerhosting": {
        let {
          meta: { platform, location }
        } = ProductKeyMapping[keyToGetProductDetails];
        return { type: "resellerhosting", platform, location };
      }
      default:
        return {};
    }
  },
  addTenuresForDomainRenewalPrices(
    tenuresByCost: Record<string, number>,
    expirytimestamp: string,
    metadata?: { maxTenure: number; minTenure: number }
  ): Record<string, number> {
    if (!metadata) {
      metadata = DefaultMetadata;
    }
    const allowedTenuresByCost = {
      [metadata.minTenure]: tenuresByCost[metadata.minTenure]
    };
    const expiryDate = convertUNIXToDate(expirytimestamp);
    const hardDeadLine = addYears(
      new Date(),
      process.env.FEATURE_ENDPOINT === "reseller"
        ? metadata.maxTenure
        : Object.values(tenuresByCost).length
    );

    const maxAllowableTenure = differenceInYears(hardDeadLine, expiryDate);
    for (
      let tenure = metadata.minTenure + 1;
      tenure <= maxAllowableTenure;
      tenure++
    ) {
      allowedTenuresByCost[tenure] =
        tenuresByCost[tenure] ?? tenuresByCost[metadata.minTenure];
    }
    return allowedTenuresByCost;
  },
  serializeActivityResponse(
    { recsinpage, recsonpage = recsinpage, recsindb, ...activities },
    isArchived = false
  ) {
    return Object.values(activities).map(activity => ({
      ...activity,
      actionAddedString: convertUTCTimestampToDate(activity.actionadded),
      isArchived
    }));
  }
};
