import api from "api";
import constants from "constants/endpoints";

export default {
  addNameServer: (orderId, { cns, ip }) =>
    api.post(`${constants.domain}add-cns`, { "order-id": orderId, cns, ip }),
  deleteNameServer: (orderId, { cns, ip }) =>
    api.post(`${constants.domain}delete-cns-ip`, {
      "order-id": orderId,
      cns,
      ip
    })
};
