import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import endpoints from "constants/endpoints";

const compatSettings = Syringe.inject("compatSettings");
const Endpoints = {
  TOKEN: `${
    compatSettings.needsCompat ? `${compatSettings.layer}/` : endpoints.apiHost
  }user/token`
};

type TokenResponse = { TOKEN: string };

export const User = {
  /**
   *
   * Creates an autologin token for the current user
   *
   * | Roles    |
   * | -------- |
   * | Customer, Reseller |
   *
   * #### Usage
   *
   * ```js
   * const { TOKEN } = await HttpApi.User.token();
   * ```
   *
   */
  async token() {
    let { authCode, TOKEN = authCode } = await api.get<{
      authCode?: string;
      TOKEN?: string;
    }>(Endpoints.TOKEN);
    return { TOKEN } as TokenResponse;
  }
};
