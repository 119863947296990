import storage from "@faizaanceg/pandora";
import classnames from "classnames";
import APIStatus from "common/api-status";
import { isSuccess } from "common/api-status/utils";
import EnableAutoRenewal from "common/auto-renewal/modals/enable";
import { GsuiteConsent } from "common/gsuite-consent";
import Header from "common/header";
import { InlineNotification } from "common/inline-notifications/";
import { areWeInMaintenance, MaintenancePage } from "common/maintenance";
import ModalHandler from "common/modal-handler";
import BillingPreferencesModal from "common/modal/billing-preferences";
import UpsellModal from "common/modal/upsell/privacy-protection/component";
import PayModal from "common/pay-buttons/pay-modal";
import ScrollSpy from "common/scroll-spy";
import { isChristmas } from "common/seasonal-greetings";
import ChristmasSnowfall from "common/seasonal-greetings/snowfall";
import {
  actions,
  actionTypes,
  selector,
  types
} from "common/session-management/ducks";
import SideBar from "common/side-bar";
import { State } from "common/state-container";
import {
  GridContainer,
  GridFooter,
  GridHeader,
  GridMain,
  GridSidebar
} from "common/styles/elements";
import { showLoader } from "common/util";
import { actions as walkThroughActions } from "common/walkthrough";
import { withUserPayments } from "common/with-user-payments";
import { oboxLanguageReverseMap } from "i18n";
import AddFunds from "modals/add-funds";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actions as commonActions } from "widgets/common/ducks";
import FooterContent from "./footer/component";
import {
  commonMessageMap,
  failureMessageMap,
  successMessageMap
} from "./popup-messages";
import TryPanel from "./try-panel";

// This order is crucial for correct loading of styles
import "styles/reset.scss";
import "styles/fonts.scss";
import "styles/core.scss";
import "./styles.scss";
import { actionTypes as inlineNotificationTypes } from "../../common/inline-notifications/ducks";

class Root extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    isPaymentsAllowedForUser: PropTypes.bool,
    location: PropTypes.shape({
      query: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    ...types
  };

  static mapDispatchToProps = {
    ...walkThroughActions,
    ...actions,
    processDispatchQueue: commonActions.processDispatchQueue
  };

  static mapStateToProps = state => ({
    langpref: state?.details?.profileInfo?.langpref || "en",
    ...selector(state)
  });

  componentDidMount() {
    this.props.getBrandingDetails(this.props.location);
  }

  monitorInfo = [
    [actionTypes.VERIFY_USER],
    [actionTypes.AUTHENTICATE_USER],
    [actionTypes.BRANDING_DETAILS_REQUEST],
    [actionTypes.ACCEPT_GSUITE_CONSENT]
  ];

  isValidState = (...apiInfo) =>
    apiInfo.every(
      ({ isFetching = false, errors = null }) => !isFetching && errors === null
    );

  componentDidUpdate(prevProps) {
    this.props.processDispatchQueue(storage.get("dispatchQueue", []));
    if (prevProps.langpref !== this.props.langpref) {
      this.props.i18n.changeLanguage(
        oboxLanguageReverseMap[this.props.langpref]
      );
    }
  }

  render() {
    const {
      location: { pathname },
      isLoggedIn,
      isUrlReal
    } = this.props;

    const isMaintenanceOn = areWeInMaintenance();
    const isOnLogin = pathname === "/login";
    const isOnWelcome = pathname === "/welcome";
    const isDashboard = pathname === "/dashboard";
    const isSupport = pathname?.includes("/support");
    if (isOnWelcome) {
      return this.props.children;
    }

    if (
      process.env.FEATURE_ENDPOINT === "reseller" &&
      !isDashboard &&
      !storage.get("disable-pro-tooltip", false)
    ) {
      storage.set("disable-pro-tooltip", true, {
        shouldPersist: true
      });
    }

    if (process.env.FEATURE_ENDPOINT !== "reseller" && isOnLogin) {
      return (
        <APIStatus
          monitors={this.monitorInfo}
          render={(verifyUserInfo, authenticateUserInfo, brandingDetailsInfo) =>
            isMaintenanceOn ? (
              <MaintenancePage />
            ) : (
              <div className="wp">
                {!isUrlReal && brandingDetailsInfo.errors && (
                  <div className="wp-error soft">
                    {/* TODO: Update UI once design is ready */}
                    This page does not exist. Please contact Support
                  </div>
                )}
                {isUrlReal && (
                  <div className="customer-login">{this.props.children}</div>
                )}
              </div>
            )
          }
        />
      );
    }

    return (
      <>
        <APIStatus monitors={this.monitorInfo}>
          {(verifyUserInfo, authenticateUserInfo, brandingDetailsInfo) => (
            <div className="wp">
              <ChristmasSnowfall
                isVisible={
                  isChristmas() &&
                  isUrlReal &&
                  this.isValidState(
                    verifyUserInfo,
                    authenticateUserInfo,
                    brandingDetailsInfo
                  ) &&
                  isLoggedIn
                }
              />
              {(verifyUserInfo.isFetching ||
                authenticateUserInfo.isFetching ||
                brandingDetailsInfo.isFetching) &&
                showLoader}
              {isUrlReal &&
                (verifyUserInfo.errors || authenticateUserInfo.errors) && (
                  <div className="wp-error soft">
                    Session expired. Please login again.
                  </div>
                )}
              {!isUrlReal && brandingDetailsInfo.errors && (
                <div className="wp-error soft">
                  {/* TODO: Update UI once design is ready */}
                  This page does not exist. Please contact Support
                </div>
              )}

              <ModalHandler
                opensOn={isSuccess(actionTypes.GSUITE_CONSENT_SHOW)}
                closesOn={isSuccess(actionTypes.ACCEPT_GSUITE_CONSENT)}
                render={(modalProps, { payload = {} }) => {
                  return <GsuiteConsent {...modalProps} {...payload} />;
                }}
              />

              {isUrlReal &&
                this.isValidState(verifyUserInfo, authenticateUserInfo) &&
                isLoggedIn && (
                  <GridContainer>
                    <State
                      initial={{
                        collapsedSidebar: false
                      }}
                      render={(state, setState) => (
                        <>
                          {this.props.brandingDetails.parentbrandingurl && (
                            <GridHeader>
                              <Header
                                isMaintenanceOn={isMaintenanceOn}
                                showWalkThroughModal={
                                  this.props.showWalkThroughModal
                                }
                                brandingName={
                                  this.props.brandingDetails.companyName
                                }
                                {...this.props.userInfo}
                                isSidebarOpen={state.collapsedSidebar}
                                sideBarCollapsed={() =>
                                  setState({
                                    collapsedSidebar: !state.collapsedSidebar
                                  })
                                }
                              />
                            </GridHeader>
                          )}
                          {isMaintenanceOn ? (
                            <MaintenancePage />
                          ) : (
                            <>
                              {!isSupport ? (
                                <GridSidebar>
                                  <ScrollSpy
                                    stickyThreshold={64}
                                    render={({ isStuck }) => (
                                      <SideBar
                                        position={
                                          isStuck ? "fixed" : "relative"
                                        }
                                        isCollapsed={state.collapsedSidebar}
                                      />
                                    )}
                                  />
                                </GridSidebar>
                              ) : null}
                              <GridMain
                                className={classnames({
                                  mobile_grid_view:
                                    process.env.FEATURE_ENDPOINT === "bigrock"
                                })}
                              >
                                <div className="wp-body">
                                  <div className="wp-routes wp-body-right">
                                    {this.props.children}
                                    {this.props.isPaymentsAllowedForUser && (
                                      <PayModal />
                                    )}
                                    {process.env.FEATURE_ENDPOINT ===
                                      "reseller" && <BillingPreferencesModal />}

                                    <UpsellModal />
                                    <AddFunds />
                                    {process.env.FEATURE_AUTO_RENEWAL && (
                                      <EnableAutoRenewal />
                                    )}
                                  </div>
                                </div>
                              </GridMain>
                            </>
                          )}
                        </>
                      )}
                    />

                    <GridFooter data-zero-margin={isMaintenanceOn}>
                      <FooterContent
                        isMaintenanceOn={isMaintenanceOn}
                        companyName={this.props.brandingDetails.companyName}
                      />
                    </GridFooter>
                  </GridContainer>
                )}
              {isLoggedIn && process.env.FEATURE_ENDPOINT === "reseller" && (
                <TryPanel />
              )}
            </div>
          )}
        </APIStatus>
        <InlineNotification
          messageType="error"
          messageMap={{
            ...failureMessageMap,
            [inlineNotificationTypes.SHOW_ERROR]: ""
          }}
        />
        <InlineNotification
          messageType="success"
          messageMap={{ ...successMessageMap, ...commonMessageMap }}
        />
      </>
    );
  }
}

export default withRouter(
  withUserPayments(
    connect(
      Root.mapStateToProps,
      Root.mapDispatchToProps
    )(withTranslation()(Root))
  )
);
